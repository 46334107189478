// shakaFactory.js
import { addBreadcrumb } from '@sentry/browser';
import { player as playerDebug } from './debug.js';
import getResourceUrl from './getResourceUrl.js';
import { Severity } from './sentry.js';
import {
  getIsOnMobile,
  getIsOnIOS,
  getIsHigherAndroidChrome80,
} from '../resource/getUserAgent.js';
import { getKeySystem } from '../resource/getDrmInfo.js';
import {
  COM_APPLE_FPS,
  COM_WIDEVINE_ALPHA,
  ORG_W3_CLEARKEY,
} from '../resource/drmConstants.js';
import { getWidevineServerCertificateBuffer } from '../resource/formatVodDrmSource.js';

const angle = () => {
  // iOS
  if (typeof window.orientation === 'number') {
    return window.orientation;
  }
  // Android
  if (screen?.orientation?.angle) {
    return screen.orientation.angle;
  }
  return 0;
};

const keySystem = getKeySystem();
const isOnMobile = getIsOnMobile();
const isOnIOS = getIsOnIOS();
const isHigherAndroidChrome80 = getIsHigherAndroidChrome80();
const defaultServersConfig = {
  'com.widevine.alpha': getResourceUrl({
    endpoint: '/drm/authorize/com.widevine.alpha',
  }).href,
  'com.microsoft.playready': getResourceUrl({
    endpoint: '/drm/authorize/com.microsoft.playready',
  }).href,
  'com.apple.fps': getResourceUrl({
    endpoint: '/drm/authorize/com.apple.fps',
  }).href,
};
const clearkeyServerEndpoint = getResourceUrl({
  endpoint: `/drm/authorize/${ORG_W3_CLEARKEY}`,
}).href;
const shakaFactoryDebug = playerDebug.extend('log:shakaFactory');
const shakaFactoryError = playerDebug.extend('error:shakaFactory');
const playerLog = ({ messages, data }) => {
  addBreadcrumb({
    category: 'player',
    level: Severity.Info,
    message: messages.join(' '),
    data,
  });
  shakaFactoryDebug(...messages, data);
};

let shakaInstance;
// For debugging (make it easy to track specific player's behaviors)
let serialNumber = 0;
// Create datached video elements for later use,
// including Short, Post, Story, Flix, etc.
let videoElementPool;

if (typeof window !== 'undefined') {
  // The Swiper keeps the Shorts a maximum of 5,
  // we double the initial number in case that
  // users swipe really fast and all the elements in use aren't detached yet.
  videoElementPool = Array(10)
    .fill()
    .map(() => document.createElement('video'));

  // For debugging
  window.videoElementPool = videoElementPool;
}

/**
 * Get a videoElement from existing pool or
 * get a newly created one if the pool is empty
 * @param {string} debugNo - a debugging number
 * @return {HTMLVideoElement} videoElement
 */
function getVideoElement(debugNo) {
  playerLog({
    messages: [`(debugNo ${debugNo}) getVideoElement`, 'init'],
  });

  const videoElement =
    videoElementPool.shift() || document.createElement('video');

  // We need a native textTrack beforehand
  // (not programmatically added, like addTextTrack);
  // otherwise iPhone Safari won't show subtitles
  const trackElement = document.createElement('track');
  trackElement.setAttribute('kind', 'subtitles');
  trackElement.setAttribute('label', `SWAG Player TextTrack ${Date.now()}`);
  videoElement.appendChild(trackElement);

  return videoElement;
}

/**
 * Import main Shaka library and some of its polyfills
 * @param {string} debugNo - a debugging number
 */
async function importShakaLibraryAndPolyfill(debugNo) {
  const detailedLogMessage = `(debugNo ${debugNo}) importShakaLibraryAndPolyfill`;
  playerLog({ messages: [detailedLogMessage, 'init'] });

  if (shakaInstance) {
    playerLog({
      messages: [detailedLogMessage, 'early return due to initialized'],
    });
    return;
  }

  if ('production' === process.env.NODE_ENV) {
    shakaInstance = await import('shaka-player');
  } else {
    shakaInstance = await import(
      'shaka-player/dist/shaka-player.compiled.debug'
    );
  }
  playerLog({ messages: [detailedLogMessage, 'package is loaded'] });

  shakaInstance.polyfill.installAll();
  if (keySystem === COM_APPLE_FPS) {
    shakaInstance.polyfill.PatchedMediaKeysApple?.install();
    playerLog({ messages: [detailedLogMessage, 'polyfill for FPS loaded'] });
  }
  playerLog({ messages: [detailedLogMessage, 'all polyfills loaded'] });
}

/**
 * A method to tell if the browser supports Shaka-Player
 * @return {boolean} isBrowserSupported
 */
export function checkIfBrowserSupported() {
  return shakaInstance?.Player?.isBrowserSupported();
}

/**
 * This is an extension for Shaka Player,
 * which aims to expose Video.js-like APIs
 * for the compatibility of existing functionality.
 * Note: `this` here refers to the shaka player instance
 * @param {string} debugNo - a debugging number
 */
async function extendShakaFunctionality(debugNo) {
  const detailedLogMessage = `(debugNo ${debugNo}) extendShakaFunctionality`;

  if (shakaInstance._isExtended) {
    playerLog({
      messages: [detailedLogMessage, 'early return due to extended'],
    });
    return true;
  }

  const { prototype } = shakaInstance.Player;

  // `this` here refers to the shaka player instance
  ['play', 'pause'].forEach(function (prop) {
    prototype[prop] = function () {
      if (!this.getMediaElement()) return;
      return this.getMediaElement()[prop]();
    };
  });
  ['paused', 'duration', 'videoHeight', 'videoWidth'].forEach(function (prop) {
    prototype[prop] = function () {
      if (!this.getMediaElement()) return;
      return this.getMediaElement()[prop];
    };
  });
  ['muted', 'loop', 'poster', 'currentTime'].forEach(function (prop) {
    prototype[prop] = function (value) {
      if (!this.getMediaElement()) return;
      if (
        typeof value === 'undefined' ||
        (prop === 'currentTime' && isNaN(value))
      ) {
        return this.getMediaElement()[prop];
      }
      this.getMediaElement()[prop] = value;
    };
  });

  prototype.el = function () {
    if (!this.getMediaElement()) return;
    return this.getMediaElement()?.parentElement;
  };
  prototype.currentSrc = prototype.getAssetUri;
  prototype.currentHeight = function () {
    return parseFloat(
      window.getComputedStyle(this.el()).getPropertyValue('height')
    );
  };
  prototype.currentWidth = function () {
    return parseFloat(
      window.getComputedStyle(this.el()).getPropertyValue('width')
    );
  };
  prototype.isFullscreen = function () {
    return !!document.fullscreenElement;
  };
  prototype.exitFullscreen = function () {
    return document.exitFullscreen();
  };
  prototype.requestFullscreen = function () {
    if (!this.getMediaElement()) return;
    return this.getMediaElement().parentElement?.requestFullscreen?.();
  };
  prototype.bufferedEnd = function () {
    if (!this.getMediaElement()) return;
    const duration = this.getMediaElement().duration;
    let buffered = this.getMediaElement().buffered;
    if (!buffered || !buffered.length) {
      const ranges = [[0, 0]];
      buffered = {
        length: 1,
        start: () => 0,
        end: () => 0,
      };
      if (window.Symbol && window.Symbol.iterator) {
        buffered[window.Symbol.iterator] = () => (ranges || []).values();
      }
    }
    let end = buffered.end(buffered.length - 1);
    if (end > duration) {
      end = duration;
    }
    return end;
  };

  prototype.on = function (eventName, eventHandler) {
    if (!this.getMediaElement()) return;
    if (eventName === 'ended' && this.playbackOrder !== this.playlistCount) {
      return;
    }
    if (!this._eventHandlers) {
      this._eventHandlers = Object.create(null);
    }
    if (this._eventHandlers[eventName]) {
      this._eventHandlers[eventName].push(eventHandler);
    } else {
      this._eventHandlers[eventName] = [eventHandler];
    }
    this.getMediaElement().addEventListener(eventName, eventHandler);
    playerLog({
      messages: [
        `(Player ${this.serialNumber}) player.on()`,
        `${eventName} listener attached`,
      ],
    });
  };
  prototype.off = function (eventName, eventHandler) {
    if (!this.getMediaElement()) return;
    if (!this._eventHandlers) {
      return false;
    }

    if (eventHandler) {
      // remove a specific listener
      const handlerIndex =
        this._eventHandlers?.[eventName]?.indexOf(eventHandler);
      if (handlerIndex >= 0) {
        this._eventHandlers[eventName].splice(handlerIndex, 1);
      }
      this.getMediaElement().removeEventListener(eventName, eventHandler);
      playerLog({
        messages: [
          `(Player ${this.serialNumber}) player.off()`,
          `one of the ${eventName} listeners removed`,
        ],
      });
    } else if (eventName) {
      // remove listeners for a specific event
      while (this._eventHandlers[eventName]?.length) {
        const handler = this._eventHandlers[eventName].pop();
        this.getMediaElement().removeEventListener(eventName, handler);
      }
      playerLog({
        messages: [
          `(Player ${this.serialNumber}) player.off()`,
          `all ${eventName} listeners removed`,
        ],
      });
    } else {
      // remove all listeners
      for (const event in this._eventHandlers) {
        while (this._eventHandlers[event]?.length) {
          const handler = this._eventHandlers[event].pop();
          this.getMediaElement().removeEventListener(event, handler);
        }
      }
      playerLog({
        messages: [
          `(Player ${this.serialNumber}) player.off()`,
          'all listeners removed',
        ],
      });
    }
  };

  prototype.ready = function (fn) {
    if (!fn) return;
    if (this._isReady) {
      fn.call(this);
    } else {
      this._readyQueue = this._readyQueue || [];
      this._readyQueue.push(fn);
    }
  };
  prototype.triggerReady = function () {
    this._isReady = true;
    if (this._readyQueue?.length) {
      this._readyQueue.forEach(function (fn) {
        fn.call(this);
      }, this);
    }
    this._readyQueue = [];
  };

  prototype.rotationchangeHandler = function () {
    const currentAngle = angle();
    playerLog({
      messages: [`(Player ${this.serialNumber}) rotationchangeHandler`, 'init'],
      data: {
        isHigherAndroidChrome80,
        currentAngle,
      },
    });
    if (isHigherAndroidChrome80) return;
    if ([90, 270, -90].includes(currentAngle)) {
      playerLog({
        messages: [
          `(Player ${this.serialNumber}) rotationchangeHandler`,
          'rotate to landscape',
        ],
        data: {
          isPaused: this.paused(),
        },
      });
      if (this.paused() === false) {
        playerLog({
          messages: [
            `(Player ${this.serialNumber}) rotationchangeHandler`,
            'request for fullscreen',
          ],
        });
        this.requestFullscreen().then(() => {
          playerLog({
            messages: [
              `(Player ${this.serialNumber}) rotationchangeHandler`,
              'end of requesting fullscreen',
            ],
          });
          screen.orientation.lock('landscape');
        });
      }
    } else if ([0, 180].includes(currentAngle)) {
      playerLog({
        messages: [
          `(Player ${this.serialNumber}) rotationchangeHandler`,
          'rotate to portrait',
        ],
        data: {
          isFullscreen: this.isFullscreen(),
        },
      });
      if (this.isFullscreen()) {
        this.exitFullscreen();
      }
    }
  };
  prototype.fullscreenchangeHandler = function () {
    playerLog({
      messages: [`(Player ${this.serialNumber}) fullscreenchangeHandler`],
      data: {
        isFullscreen: this.isFullscreen(),
      },
    });
    if (this.isFullscreen()) {
      screen.orientation.lock('landscape');
    }
  };
  prototype.landscapeFullscreen = function (options) {
    // options = {
    //   fullscreen: {
    //     enterOnRotate: !isOnIOS,
    //     alwaysInLandscapeMode: true,
    //     iOS: false,
    //   },
    // }
    if (isOnMobile || isOnIOS) {
      // bind player instance to the handlers
      this.rotationchangeHandler = this.rotationchangeHandler.bind(this);
      this.fullscreenchangeHandler = this.fullscreenchangeHandler.bind(this);

      this.ready(() => {
        if (options.fullscreen.enterOnRotate) {
          if (isOnIOS) {
            // Have to remove listeners explicitly on our own
            window.addEventListener(
              'orientationchange',
              this.rotationchangeHandler
            );
          } else if (screen && screen.orientation) {
            // addEventListener('orientationchange') is not a user interaction on Android.
            // Would be overwritten by new player (no need to worry that handlers count will grow)
            screen.orientation.onchange = this.rotationchangeHandler;
          }
        }

        if (options.fullscreen.enterOnRotate) {
          document.addEventListener(
            'fullscreenchange',
            this.fullscreenchangeHandler
          );
        }
      });
    }
  };

  shakaInstance._isExtended = true;
  playerLog({
    messages: [detailedLogMessage, 'extend successfully'],
  });
}

/**
 * Create a new shaka player instance
 * @param {string} debugNo - a debugging number
 */
async function createShakaPlayerInstance(debugNo) {
  playerLog({
    messages: [`(debugNo ${debugNo}) createShakaPlayerInstance`, 'init'],
  });

  const videoElement = getVideoElement(debugNo);
  const player = new shakaInstance.Player(videoElement);
  player.serialNumber = ++serialNumber;
  playerLog({
    messages: [
      `(debugNo ${debugNo})(Player ${player.serialNumber}) createShakaPlayerInstance`,
      'new player created',
    ],
  });

  return player;
}

/**
 * Set some configurations, such as DRM-related settings, to a player instance
 * @param {object} player - a shaka player instance to be configured
 * @return {object} a configured shaka player instance
 */
export function updateShakaPlayerConfiguration(player) {
  const { debugNo, robustness, widevineServerCertificateBase64 } = player;
  const detailedLogMessage =
    `(debugNo ${debugNo})(Player ${player.serialNumber})` +
    (player.playlistCount
      ? `(playback order: ${player.playbackOrder} / ${player.playlistCount})`
      : '(single source; no playlist)') +
    ' updateShakaPlayerConfiguration';
  playerLog({
    messages: [detailedLogMessage, 'init'],
    data: {
      keySystem,
      defaultPlayerConfig: player.getConfiguration(),
    },
  });

  if (player._isPlayerConfigurationSet) {
    playerLog({
      messages: [
        detailedLogMessage,
        'early return because the configuration has set for this player',
      ],
    });
    return true;
  }
  player._isPlayerConfigurationSet = true;

  // Just in case the key system was mistakenly identified as an incorrect value,
  // setting multiple servers config to give Shaka a chance
  // to fallback to another key system.
  // But once there's clearkey in servers,
  // shaka will ignore supports check and ALWAYS use clearkey,
  // so we need to conditionally add clearkey endpoint.
  const servers =
    ORG_W3_CLEARKEY !== keySystem
      ? defaultServersConfig
      : isOnMobile
        ? {
            [ORG_W3_CLEARKEY]: clearkeyServerEndpoint,
          }
        : {
            ...defaultServersConfig,
            [ORG_W3_CLEARKEY]: clearkeyServerEndpoint,
          };
  let advanced;
  if (COM_APPLE_FPS === keySystem) {
    advanced = {
      'com.apple.fps': {
        videoRobustness: robustness,
        serverCertificateUri: getResourceUrl({
          endpoint: '/drm/certs/com.apple.fps.cer',
        })?.href,
      },
    };
  } else if (ORG_W3_CLEARKEY !== keySystem) {
    advanced = {
      [keySystem]: {
        videoRobustness: robustness,
      },
    };

    if (COM_WIDEVINE_ALPHA === keySystem && widevineServerCertificateBase64) {
      advanced[keySystem].serverCertificate =
        getWidevineServerCertificateBuffer({
          widevineServerCertificateBase64,
        });
    }
  }

  const result = {
    drm: {
      servers,
      initDataTransform: (initData, initDataType, drmInfo) => {
        if (initDataType !== 'skd') return initData;
        const { StringUtils, FairPlayUtils } = shakaInstance.util;
        const skdUri = StringUtils.fromBytesAutoDetect(initData);
        const contentId = skdUri?.split('://')?.[1];
        const cert = drmInfo.serverCertificate;
        playerLog({
          messages: ['initDataTransform'],
          data: { drmInfo, skdUri, contentId },
        });
        return FairPlayUtils.initDataTransform(initData, contentId, cert);
      },
      advanced,
    },
  };

  player.configure(result);
  const playerConfig = player.getConfiguration();
  playerLog({
    messages: [detailedLogMessage, 'configured'],
    data: {
      playerConfig,
      drmConfig: playerConfig?.drm,
      advancedDrmConfig: playerConfig?.drm?.advanced,
    },
  });

  return player;
}

/**
 * Register a request filter to shaka player's networking engine.
 * @param {object} player - a shaka player instance to be configured
 * @return {void}
 */
export function registerShakaPlayerRequestFilter(player) {
  const detailedLogMessage =
    `(debugNo ${player.debugNo})(Player ${player.serialNumber})` +
    (player.playlistCount
      ? `(playback order: ${player.playbackOrder} / ${player.playlistCount})`
      : '(single source; no playlist)') +
    ' registerShakaPlayerRequestFilter';

  if (player._isPlayerRequestFilterRegistered) {
    playerLog({
      messages: [
        detailedLogMessage,
        'early return because the request filter has registered for this player',
      ],
    });
    return true;
  }
  player._isPlayerRequestFilterRegistered = true;

  player.getNetworkingEngine().registerRequestFilter(function (type, request) {
    if (type === shakaInstance.net.NetworkingEngine.RequestType.LICENSE) {
      const [keyId] = [...request.drmInfo.keyIds];
      const kid =
        keyId ||
        shakaInstance.util.FairPlayUtils.defaultGetContentId(request.initData);
      if (kid) {
        request.headers['X-DRM-KID'] = kid;
      }
      if (player.token) {
        request.headers['Authorization'] = `Bearer ${player.token}`;
      }
      if (player.clientId) {
        request.headers['X-Client-ID'] = player.clientId;
      }
      playerLog({
        messages: [detailedLogMessage, 'registered'],
        data: { request, uris: request.uris, headers: request.headers },
      });
    }
  });
}

/**
 * Get videoElements in the pool
 * @return {HTMLVideoElement[]} all the videoElements in the pool
 */
export function getVideoElementPool() {
  return videoElementPool;
}

/**
 * Started from importing Shaka's library and polyfills,
 * extending extra functionality to shakaInstance.Player,
 * all the way to create a final shaka player instance to use.
 * @param {object} option
 * @param {string} option.debugNo - a debugging number
 * @return {Promise} promise resolved with a shaka player instance
 */
export async function create({ debugNo }) {
  playerLog({ messages: [`(debugNo ${debugNo}) create`] });
  let player;
  try {
    // download core Shaka library and some necessary polyfills
    await importShakaLibraryAndPolyfill(debugNo);
    // encapsulate original Shaka to expose extra functionality
    await extendShakaFunctionality(debugNo);
    // initialize a new player instance
    player = await createShakaPlayerInstance(debugNo);
    return player;
  } catch (error) {
    shakaFactoryError(`(debugNo ${debugNo}) Error occurs`, error);
    return { error };
  }
}

/**
 * Destroy a shaka player instance when it's not needed anymore.
 * @param {object} option
 * @param {string} option.debugNo - a debugging number
 * @param {object} option.player - a shaka player instance
 * @param {boolean} option.shouldRecycleVideoElement - to see if we should reuse videoElement
 * @return {Promise} promise
 */
export async function destroy({
  debugNo,
  player,
  shouldRecycleVideoElement = true,
}) {
  const detailedLogMessage = `(debug ${debugNo})(Player ${player.serialNumber}) destroy`;
  playerLog({ messages: [detailedLogMessage, 'init'] });

  await player.unload();
  playerLog({ messages: [detailedLogMessage, 'source unloaded'] });

  player.getNetworkingEngine().clearAllRequestFilters();
  playerLog({ messages: [detailedLogMessage, 'request filters cleared'] });

  player._readyQueue = [];
  player._isReady = false;
  await player.resetConfiguration();
  playerLog({ messages: [detailedLogMessage, 'player reset'] });

  const videoElement = player.getMediaElement();
  const timestamp = Date.now() + ''; // convert it to string
  if (!player.videoElementDeletedTimeSet) {
    player.videoElementDeletedTimeSet = [];
  }
  player.videoElementDeletedTimeSet.push(timestamp);
  videoElement.dataset.deletedTime = timestamp; // string in essence
  await player.detach();
  videoElementPool.push(
    shouldRecycleVideoElement ? videoElement : document.createElement('video')
  );
  videoElement?.parentNode?.removeChild(videoElement);
  playerLog({
    messages: [detailedLogMessage, 'player detached'],
    data: { shouldRecycleVideoElement },
  });

  player.playbackOrder = undefined;
  player.playlistCount = undefined;

  player.destroy();

  playerLog({ messages: [detailedLogMessage, 'player is destroyed'] });
}
