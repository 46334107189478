// ShakaPlayer.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withScope, captureException, addBreadcrumb } from '@sentry/browser';
import isEqual from 'lodash/isEqual.js';

import { Severity } from '../resource/sentry.js';
import { EVENT_MAP, formatSource } from '../resource/playerUtils.js';
import { player as playerDebug } from '../resource/debug.js';
import { DrmFeatureName } from '../resource/drmConstants.js';
import { PlayerContext } from '../resource/contexts.js';
import playerPool from '../resource/playerPool.js';
import {
  checkIfBrowserSupported,
  updateShakaPlayerConfiguration,
  registerShakaPlayerRequestFilter,
} from '../resource/shakaFactory.js';

const playerDebugLog = playerDebug.extend('log:ShakaPlayer');
const playerError = playerDebug.extend('error:ShakaPlayer');
const playerLog = ({ messages, data }) => {
  addBreadcrumb({
    category: 'player',
    level: Severity.Info,
    message: messages.join(' '),
    data,
  });
  playerDebugLog(...messages, data);
};

// Store handler names (the handlers created in this component)
const mainEventHandlers = Object.create(null);
// Store handlers (the handlers passed via props)
const extraEventHandlers = Object.create(null);

const mainVideoEvents = [
  'loadedmetadata',
  'play',
  'pause',
  'ended',
  // 'error',
];
mainVideoEvents.forEach(event => {
  const capitalizedEvent = event.charAt(0).toUpperCase() + event.slice(1);
  mainEventHandlers[event] = `handle${capitalizedEvent}`;
});

// This is for debugging purpose.
// It helps us figure out how many requests are still waiting,
// and which one has required resource successfully.
const ACQUISITION_HISTORY_SIZE = 30;
const acquisitionHistory = new Map();
if (typeof window !== 'undefined') {
  window.acquisitionHistory = acquisitionHistory;
}

const getDebugNo = () => {
  let num = Math.floor(Math.random() * 1000);
  num = num.toString();
  while (num.length < 3) num = '0' + num; // pad
  return num;
};

class ShakaPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.videoContainerRef = React.createRef();
    this._player = null;
  }

  setPlayer = () => undefined;

  captureException = error => {
    withScope(scope => {
      scope.setFingerprint(['shaka-player-error', error.code]);

      // Additional Data
      scope.setExtra('source', this.props.source);
      scope.setExtra('drmFeatureName', this.props.drmFeatureName);
      scope.setExtra('key system (passed from props)', this.props.keySystem);
      const player = this._player;
      if (player) {
        scope.setExtra('key system (used by Shaka)', player.keySystem());
        scope.setExtra('isPaused', player.paused());
        scope.setExtra('duration', player.duration());
        scope.setExtra('currentSrc', player.currentSrc());
        scope.setExtra('active player No.', player.serialNumber);
      } else {
        scope.setExtra('active player No.', 'N/A');
      }
      scope.setExtra('error', error);
      scope.setExtra(
        'acquisitionHistory',
        Object.fromEntries(acquisitionHistory.entries())
      );

      // Sample error object on Sentry:
      /*
      {
        category: 3, 
        code: 3015, 
        data: [ [DOMException] ], 
        handled: False, 
        severity: 2
      }
      */
      let errorMessage = error.message || '';
      let domException;
      if (Array.isArray(error?.data)) {
        const flattened = error.data.flatMap(el => el);
        domException = flattened.find(el => el instanceof DOMException);
      }
      if (domException) {
        errorMessage = domException.message || '';
        scope.setExtra('DOMExceptionCode', domException.code);
        scope.setExtra('DOMExceptionMessage', domException.message);
        scope.setExtra('DOMExceptionName', domException.name);
        scope.setExtra('DOMException', domException);
      }
      captureException(
        new Error(`SHAKA-PLAYER CODE "${error.code}" ${errorMessage}`)
      );
    });
  };

  /**
   * Add necessary event handlers to the video element
   */
  bindListeners = ({ debugNo, player }) => {
    const detailedLogMessage =
      `(debugNo ${debugNo})(Player ${player.serialNumber})` +
      (player.playlistCount
        ? `(playback order: ${player.playbackOrder} / ${player.playlistCount})`
        : '(single source; no playlist)') +
      ' bindListeners';
    playerLog({
      messages: [detailedLogMessage, 'init'],
    });

    // The handlers that are created by this component.
    mainVideoEvents.forEach(eventName => {
      player
        .getMediaElement()
        .addEventListener(eventName, this[mainEventHandlers[eventName]]);
    });
    playerLog({
      messages: [detailedLogMessage, 'main events bound'],
    });

    // The handlers that are passed via props.
    Object.keys(EVENT_MAP).forEach(key => {
      const eventName = EVENT_MAP[key];
      const logTag = key.slice(2).toLowerCase();
      const callback = this.props[key];
      if (typeof callback === 'function') {
        const eventHandler = event => {
          callback(event, player);
          if (!['progress', 'timeupdate'].includes(logTag)) {
            playerLog({
              messages: [
                `(debugNo ${debugNo})(Player ${player.serialNumber})` +
                  (player.playlistCount
                    ? `(playback order: ${player.playbackOrder} / ${player.playlistCount})`
                    : '(single source; no playlist)'),
                logTag,
                'fired',
              ],
              data: { target: event.target },
            });
          }
        };

        // keep corresponding handlers for multiple players
        if (extraEventHandlers[eventName]) {
          extraEventHandlers[eventName].push({ player, eventHandler });
        } else {
          extraEventHandlers[eventName] = [{ player, eventHandler }];
        }

        player.getMediaElement().addEventListener(eventName, eventHandler);
      }
    });

    playerLog({
      messages: [
        detailedLogMessage,
        `extra events (${Object.keys(EVENT_MAP).join()}) bound`,
      ],
    });

    player.addEventListener('error', this.handlerPlayerError);

    return player;
  };

  /**
   * Get the sources of trailer, splash, or main content
   */
  getPlaylist({ debugNo, source }) {
    const {
      shouldUseTrailer,
      shouldCombineTrailer,
      shouldUseSplash,
      splashVideo,
    } = this.props;

    playerLog({
      messages: [`(debugNo ${debugNo}) getPlaylist`],
      data: {
        source,
        props: {
          shouldUseTrailer,
          shouldCombineTrailer,
          shouldUseSplash,
        },
      },
    });

    const sources = formatSource({
      dash: source.dash,
      hls: source.hls,
    });

    if (shouldUseTrailer) {
      const trailer = formatSource({ mp4: source.mp4 });
      return [trailer[0].src];
    } else if (shouldCombineTrailer) {
      const trailer = formatSource({ mp4: source.mp4 });
      return [trailer[0].src, sources[0].src];
    } else if (shouldUseSplash) {
      const splash = formatSource(splashVideo);
      if (splash.length) {
        return [splash[0].src, sources[0].src];
      }
    }

    return [sources[0].src];
  }

  /**
   * Notify the other components that there is a new active player
   */
  setActivePlayer(player, debugNo) {
    const detailedLogMessage =
      `(debugNo ${debugNo})(Player ${player.serialNumber})` +
      (player.playlistCount
        ? `(playback order: ${player.playbackOrder} / ${player.playlistCount})`
        : '(single source; no playlist)') +
      ' setActivePlayer';
    this.props.onSetup(player);
    this.setPlayer(player);
    player.triggerReady();
    playerLog({
      messages: [
        detailedLogMessage,
        `Player ${player.serialNumber} has activated`,
      ],
    });
  }

  reload(player) {
    // Not sure if the thumbnail should be kept,
    // but video.js doesn't display thumbnail when reloading the same source.
    // Also, since Safari always display first frame,
    // let's remove thumbnail first to avoid:
    // poster -> first frame -> video
    player.getMediaElement().removeAttribute('poster');

    player.isReloadRequired = false;

    playerLog({
      messages: ['reload', 'start'],
      data: {
        src: player.getAssetUri(),
        currentTime: player.currentTime(),
      },
    });
    player.load(player.getAssetUri(), player.currentTime()).then(() => {
      this.setReloadTimer(player);
      this.props.setIsVideoReloaded(true);
      playerLog({ messages: ['reload', 'end'] });
    });
  }

  deactivatePlayer({ player, debugNo, reason }) {
    if (!player) {
      playerLog({
        messages: [
          `(debug ${debugNo}) deactivatePlayer (${reason})`,
          'early return due to no player found',
        ],
      });
      return;
    }

    const videoElement = player.getMediaElement();
    const timestamp = Date.now() + ''; // convert it to string
    player.videoElementDeletedTime = timestamp;
    videoElement.dataset.deletedTime = timestamp; // string in essence

    window.clearTimeout(player.reloadTimer);
    player.isReloadRequired = undefined;
    player.reloadTimer = undefined;

    if (player._endedHandler) {
      videoElement.removeEventListener('ended', player._endedHandler);
    }
    this.unbindListeners(player, debugNo);
    player.unload().then(() => {
      playerLog({
        messages: [
          `(debug ${debugNo})(Player ${player.serialNumber}) deactivatePlayer`,
          'unloaded & unbound',
        ],
      });
      player._isReady = false;
      player._readyQueue = [];
      this.releasePlayer({
        debugNo,
        player,
        reason: 'deactivating the player',
      });
    });
  }

  releasePlayer = ({ debugNo, player, reason }) => {
    playerLog({
      messages: [
        `(debugNo: ${debugNo}) releasePlayer`,
        `about to release it due to: ${reason}`,
      ],
      data: playerPool.getStatus(),
    });

    if (this._player) {
      this._player = undefined;
      if (typeof window !== 'undefined') {
        window.activeShakaPlayer = undefined;
      }
    } else {
      playerLog({
        messages: [
          `(debugNo: ${debugNo}) releasePlayer`,
          'early return b/c this._player has been reset',
        ],
        data: playerPool.getStatus(),
      });
      return;
    }

    // `acquisitionHistory` is just for debugging.
    // It keeps track of the status of every requests.
    const acquiringTimestamp = player.acquiringTimestamp;
    acquisitionHistory
      .get(acquiringTimestamp)
      .acquisitionStatus.unshift(`releasing due to ${reason}`);
    playerPool.releasePlayer(player).then(() => {
      playerLog({
        messages: [
          `(debug ${debugNo}) releasePlayer`,
          `player released (${player._lastAcquiringTimestamp || ''})`,
        ],
        data: playerPool.getStatus(),
      });

      // It's necessary for PostAssetFullscreenModal.
      // (_isPlayerInModalCreated should be reset to bring back the player covered by modal)
      this.resetReleasingFlags();
      acquisitionHistory
        .get(acquiringTimestamp)
        .acquisitionStatus.unshift('released');
    });
  };

  unbindListeners(player, debugNo) {
    const detailedLogMessage = `(debug ${debugNo})(Player ${player.serialNumber}) unbindListeners`;
    playerLog({
      messages: [detailedLogMessage, 'init'],
    });

    player.removeEventListener('error', this.handlerPlayerError);

    mainVideoEvents.forEach(eventName => {
      player
        .getMediaElement()
        .removeEventListener(eventName, this[mainEventHandlers[eventName]]);
    });
    playerLog({ messages: [detailedLogMessage, 'main events unbound'] });

    Object.keys(EVENT_MAP).forEach(key => {
      const eventName = EVENT_MAP[key];
      const callback = this.props[key];
      if (typeof callback === 'function') {
        const extraEventHandler = extraEventHandlers[eventName];
        const handlerIndex = extraEventHandler
          .map(handlerEntry => handlerEntry.player)
          .indexOf(player);
        if (handlerIndex >= 0) {
          const handlerEntry = extraEventHandler.splice(handlerIndex, 1)[0];
          player
            .getMediaElement()
            .removeEventListener(eventName, handlerEntry.eventHandler);
        } else {
          playerLog({
            messages: [
              detailedLogMessage,
              'no extra handlers found as unbinding',
            ],
          });
        }
      }
    });

    if (player._endedHandler) {
      player
        .getMediaElement()
        .removeEventListener('ended', player._endedHandler);
      player._endedHandler = undefined;
    }

    playerLog({ messages: [detailedLogMessage, 'extra events unbound'] });

    // Just in case there are still listeners left with an inactive player,
    // especially for those are not controlled by ShakaPlayer.jsx,
    // e.g., VideoWatermark.jsx, FlixPlayerNoSeekDetector.js
    player.off();
    playerLog({
      messages: [detailedLogMessage, 'events bound with ON are unbound'],
    });

    // fullscreen related
    window.removeEventListener(
      'orientationchange',
      player.rotationchangeHandler
    );
    document.removeEventListener(
      'fullscreenchange',
      player.fullscreenchangeHandler
    );
  }

  startProgressAnimation() {
    const isVideo = 0 === this.props.mediaType.search('video');
    const hasCallback = typeof this.props.onProgressUpdate === 'function';
    if (!isVideo || !hasCallback) {
      return null;
    }

    this.fpsInterval = 1000 / 12;
    this.then = Date.now();

    this.timer = window.requestAnimationFrame(this.updateProgress);
  }

  updateProgress = () => {
    this.timer = window.requestAnimationFrame(this.updateProgress);

    const now = Date.now();
    const elapsed = now - this.then;

    if (elapsed > this.fpsInterval) {
      this.then = now - (elapsed % this.fpsInterval);

      if (this._player) {
        const currentTime = this._player.currentTime();
        const duration = this._player.duration();
        this.props.onProgressUpdate({ currentTime, duration });
      }
    }
  };

  // It's not a native video event; this comes from the player.
  handlerPlayerError = event => {
    const player = event.target;
    playerError(`(Player ${player.serialNumber}) Errors from Shaka:`, event);
    this.captureException(event.detail);
  };

  handleLoadedmetadata = event => {
    playerLog({ messages: ['handleLoadedmetadata'] });

    this.props.onLoadedMetadata(event, this._player);
  };

  handlePlay = () => {
    const player = this._player;
    const { isNeedReloadCycle, onPlay } = this.props;
    playerLog({
      messages: ['handlePlay'],
      data: {
        isNeedReloadCycle,
        isReloadRequired: player.isReloadRequired,
      },
    });
    if (isNeedReloadCycle && player.isReloadRequired) {
      this.reload(player);
    }
    window.cancelAnimationFrame(this.timer);
    this.startProgressAnimation();
    onPlay();
  };

  handlePause = event => {
    playerLog({ messages: ['handlePause'], data: event });
    this.props.onPause();
    window.cancelAnimationFrame(this.timer);
  };

  handleEnded = event => {
    playerLog({ messages: ['handleEnded'], data: event });
    window.cancelAnimationFrame(this.timer);

    const player = this._player;
    if (
      player?.playlistCount &&
      player?.playlistCount !== player?.playbackOrder
    ) {
      playerLog({
        messages: [
          'handleEnded',
          'early return due to not the last video in playlist',
        ],
        data: {
          playbackOrder: player.playbackOrder,
          playlistCount: player.playlistCount,
        },
      });
      return;
    }

    this.props.onEnded();
  };

  // Some special tags need to be reset.
  // Otherwise a newly created player instance would malfunction
  resetReleasingFlags = () => {
    // If we don't clear `_unmounting`, we'll encounter an issue after we upgrade next to 14, which is:
    // In reactStrictMode, some lifecycle methods, such as componentDidMount, would run twice on purpose,
    // also, these two runs would share the same `this`,
    // in hence, the flag set by the previous run may affect the later run,
    // that's why we should clear `_unmounting` here to avoid the wrong setting for the second run in reactStrictMode.
    this._unmounting = undefined;
    this._isPostInactive = undefined;
    this._isShortInactive = undefined;
    this._isPlayerInModalCreated = undefined;
  };

  playback = debugNo => {
    const {
      messageId,
      source,
      isDrmLicenseAvailabilityFetched,
      shouldAutoplayPost,
      drmFeatureName,
      isActiveVideo,
    } = this.props;

    if (!isDrmLicenseAvailabilityFetched) {
      playerLog({
        messages: [
          `(debugNo: ${debugNo}) playback`,
          'early return due to DrmLicenseAvailability not fetched yet',
        ],
        data: { messageId },
      });
      return;
    }

    // (Case 1)
    // If there is an existing player, reuse it rather than asking for a new one.
    if (this._player) {
      playerLog({
        messages: [`(debugNo: ${debugNo}) playback`, 'reuse player'],
      });

      const player = this._player;
      if (player._endedHandler) {
        player
          .getMediaElement()
          .removeEventListener('ended', player._endedHandler);
      }

      this.playWithPlaylist({ debugNo, source, player, isPlayerReused: true });
      return;
    }

    // (Case 2)
    // Ask for a new player instance from the pool
    const acquiringTimestamp = Date.now();
    this._lastAcquiringTimestamp = acquiringTimestamp;
    playerLog({
      messages: [`(debugNo: ${debugNo}) playback`, 'about to acquirePlayer'],
      data: { messageId, poolStatus: playerPool.getStatus() },
    });

    // `acquisitionHistory` is only for debugging.
    acquisitionHistory.set(acquiringTimestamp, {
      acquisitionStatus: ['waiting'],
      messageId,
      debugNo,
    });
    for (const key of acquisitionHistory.keys()) {
      if (acquisitionHistory.size > ACQUISITION_HISTORY_SIZE) {
        acquisitionHistory.delete(key);
      } else {
        break;
      }
    }
    playerPool.acquirePlayer().then(async response => {
      if (response.error) {
        playerError(`(debugNo: ${debugNo}) failed to acquire`, response.error);
        this.captureException(response.error);
        this.releasePlayer({
          debugNo,
          player: response,
          reason: 'an error occurred as acquiring',
        });
      } else {
        playerLog({
          messages: [`(debugNo: ${debugNo}) playback`, 'acquired successfully'],
          data: { messageId, poolStatus: playerPool.getStatus() },
        });
        acquisitionHistory
          .get(acquiringTimestamp)
          .acquisitionStatus.unshift('acquired');

        const player = response;
        player.debugNo = debugNo;
        // Give a player an identifier every time.
        // There might be several requests at the same time, but only the last one matters.
        player.acquiringTimestamp = acquiringTimestamp;
        // Keep it since we need to specifically tell pool which player needs to be released.
        this._player = player;
        if (typeof window !== 'undefined') {
          window.activeShakaPlayer = player; // for debug
        }

        // Since `acquirePlayer` is asynchronous,
        // the new operations might happen during (or before, if it's waiting) the acquisition process.
        // For example, if a user enters a page and leaves it very quickly,
        // the expected player instance may not be ready/created while the component is unmounting.
        // When the actual acquisition process running in this kind of situation,
        // it doesn't need to go further because the following process is gonna be useless.
        if (
          this._unmounting ||
          this._isPostInactive ||
          this._isShortInactive ||
          this._isPlayerInModalCreated
        ) {
          const reason = this._unmounting
            ? 'the component is unmounting'
            : this._isPlayerInModalCreated
              ? 'post detail is covered by modal'
              : this._isPostInactive
                ? 'the Post is inactive'
                : 'the Short is inactive';

          // Can't just wait playerPool.releasePlayer() to resetReleasingFlags()
          // It might be too late since playerPool.releasePlayer() is asynchronous.
          this.resetReleasingFlags();
          this.releasePlayer({ debugNo, player, reason });
          return;
        }

        // Release SHORT when it's staled.
        if (drmFeatureName === DrmFeatureName.SHORT && !isActiveVideo) {
          this.releasePlayer({
            debugNo,
            player,
            reason: 'the SHORT is not active now',
          });
          return;
        }

        // Release POST when it's staled.
        if (drmFeatureName === DrmFeatureName.POST && !shouldAutoplayPost) {
          this.releasePlayer({
            debugNo,
            player,
            reason: 'the POST should not auto play now',
          });
          return;
        }

        // Only the last request should keep the instance.
        if (acquiringTimestamp !== this._lastAcquiringTimestamp) {
          this.releasePlayer({
            debugNo,
            player,
            reason: 'this acquirement is staled',
          });
          return;
        }

        if (!this.videoContainerRef?.current) {
          playerError(`(debugNo: ${debugNo}) no video container found`);
          this.releasePlayer({
            debugNo,
            player,
            reason: 'no video container was found',
          });
          return;
        }

        this.videoContainerRef.current.prepend(player.getMediaElement());

        this.playWithPlaylist({
          debugNo,
          source,
          player,
          isPlayerReused: false,
        });
      }
    });
  };

  /**
   * Get a playlist,
   * notify all the components that the player is ready,
   * and then load a video.
   */
  playWithPlaylist = ({ debugNo, source, player, isPlayerReused }) => {
    const playlist = this.getPlaylist({ debugNo, source });
    playerLog({
      messages: [`(debugNo: ${debugNo}) playWithPlaylist`, 'playlist is ready'],
      data: { playlist },
    });

    this.setPlayerParams({
      player,
      playlist,
      debugNo,
      isPlayerReused,
    });
    const autoplay = player.getMediaElement().autoplay;
    playerLog({
      messages: [
        `(debugNo: ${debugNo}) playWithPlaylist`,
        'setPlayerParams has set. about to set player to active and load source',
      ],
      data: { autoplay },
    });

    // (2024.06)
    // No need to set player again if the player is reused.
    // Otherwise, it will send redundant PLAYER_VISIBLE tracking events.
    // (We would send PLAYER_VISIBLE when the player instance is acquired by other components,
    // no matter the player instance is the same or not)
    if (!isPlayerReused) this.setActivePlayer(player, debugNo);
    this.loadSource({ player, debugNo, src: playlist[0] });
  };

  /**
   * Leverage this method to update required information to player instance,
   * since we can't pass any parameters through genericPool's create()
   */
  setPlayerParams = ({ player, playlist, debugNo, isPlayerReused }) => {
    const {
      token,
      clientId,
      isMuted,
      option,
      messageId,
      assetId,
      robustness,
      openPlaybackNotSupportAlert,
      widevineServerCertificateBase64,
    } = this.props;
    playerLog({
      messages: [`(debugNo: ${debugNo}) setPlayerParams`],
      data: { isPlayerReused, option },
    });
    // stick to the following order:
    // 1. Set playbackOrder, playlistCount & other information
    // 2. Bind listeners
    // 3. Set _endedHandler if needed
    const playlistCount = playlist.length;
    player.playbackOrder = playlistCount > 1 ? 1 : undefined;
    player.playlistCount = playlistCount > 1 ? playlistCount : undefined;
    player.token = token || '';
    player.clientId = clientId || '';
    player.robustness = robustness;
    player.widevineServerCertificateBase64 = widevineServerCertificateBase64;

    if (!checkIfBrowserSupported()) {
      // It only shows once in 24 hours
      openPlaybackNotSupportAlert();
    }
    updateShakaPlayerConfiguration(player);
    registerShakaPlayerRequestFilter(player);

    const videoElement = player.getMediaElement();
    videoElement.removeAttribute('poster');
    videoElement.removeAttribute('loop');
    videoElement.autoplay = true;
    videoElement.playsInline = true;
    if (videoElement && option) {
      for (const [key, value] of Object.entries(option)) {
        videoElement[key] = value;
      }
    }
    videoElement.muted = isMuted; // global setting; always takes precedence
    videoElement.dataset.debugNo = debugNo;
    videoElement.dataset.messageId = messageId;
    videoElement.dataset.assetId = assetId;

    if (!isPlayerReused) {
      this.bindListeners({ debugNo, player });
    }

    if (player.playlistCount) {
      player._endedHandler = () => {
        if (player.playbackOrder < player.playlistCount) {
          this.loadSource({
            player,
            debugNo,
            src: playlist[player.playbackOrder++],
          });
        }
      };
      player.getMediaElement().addEventListener('ended', player._endedHandler);
    }
  };

  loadSource = ({ player, debugNo, src }) => {
    window.clearTimeout(player.reloadTimer);
    return player.unload().then(() =>
      player
        .load(src)
        .then(() => {
          playerLog({
            messages: [
              `(debugNo: ${debugNo}) playback`,
              `${src} loaded successfully`,
            ],
          });
          this.setReloadTimer(player);
        })
        .catch(error => {
          // Only capture unexpected errors
          if (error.code !== 7000) {
            playerError(
              `(debugNo: ${debugNo}) playback - failed to load ${src}`,
              error
            );
            this.captureException(error);
            this.deactivatePlayer({
              player,
              debugNo,
              reason: 'an error occurred',
            });
          }
        })
    );
  };

  setReloadTimer = player => {
    const { isNeedReloadCycle, drmLicenseDuration } = this.props;
    if (isNeedReloadCycle) {
      player.reloadTimer = setTimeout(() => {
        if (player.paused()) {
          player.isReloadRequired = true;
        } else {
          this.reload(player);
        }
      }, drmLicenseDuration * 1000);
    }
  };

  componentDidMount() {
    const { messageId, drmFeatureName, isActiveVideo, shouldAutoplayPost } =
      this.props;
    const debugNo = getDebugNo();
    playerLog({
      messages: [`(debug ${debugNo}) componentDidMount`],
      data: { messageId },
    });
    if (drmFeatureName === DrmFeatureName.SHORT) {
      isActiveVideo && this.playback(debugNo);
    } else if (drmFeatureName === DrmFeatureName.POST) {
      shouldAutoplayPost && this.playback(debugNo);
    } else {
      this.playback(debugNo);
    }
  }

  async componentDidUpdate(prevProps) {
    // [HEADS-UP]
    // Since it takes some time to initializePlayer,
    // `this._player` might not be ready yet as new props are updated!
    const debugNo = this._player?.debugNo || getDebugNo();
    const {
      messageId,
      assetId,
      isDrmLicenseAvailabilityFetched,
      source,
      clientId,
      token,
      canViewMedia,
      isMuted,
      option,
      shouldAutoplayPost,
      isActiveVideo,
      drmFeatureName,
      enabledImageFullscreenModal,
      isFullscreenModalOpened,
    } = this.props;
    const {
      messageId: prevMessageId,
      assetId: prevAssetId,
      source: prevSource,
      isDrmLicenseAvailabilityFetched: prevIsDrmLicenseAvailabilityFetched,
      clientId: prevClientId,
      token: prevToken,
      canViewMedia: prevCanViewMedia,
      isMuted: prevIsMuted,
      option: prevOption,
      shouldAutoplayPost: prevShouldAutoplayPost,
      isActiveVideo: prevIsActiveVideo,
      isFullscreenModalOpened: prevIsFullscreenModalOpened,
    } = prevProps;
    const isShort = drmFeatureName === DrmFeatureName.SHORT;
    const isPost = drmFeatureName === DrmFeatureName.POST;
    const isModalOpener = isPost && enabledImageFullscreenModal;

    if (
      isPost &&
      shouldAutoplayPost !== prevShouldAutoplayPost &&
      !shouldAutoplayPost
    ) {
      this._isPostInactive = true;
      this.deactivatePlayer({
        player: this._player,
        debugNo,
        reason: 'Post is inactive',
      });
      return;
    }

    if (isShort && isActiveVideo !== prevIsActiveVideo && !isActiveVideo) {
      this._isShortInactive = true;
      this.deactivatePlayer({
        player: this._player,
        debugNo,
        reason: 'Short is inactive',
      });
      return;
    }

    if (
      isModalOpener &&
      isFullscreenModalOpened &&
      isFullscreenModalOpened !== prevIsFullscreenModalOpened
    ) {
      this._isPlayerInModalCreated = true;
      this.deactivatePlayer({
        player: this._player,
        debugNo,
        reason: 'Post detail is covered by a newly opened modal',
      });
      return;
    }

    // Keep track of the critical props to determine if we have to load/reload source.
    const diff = Object.create(null);
    if (token !== prevToken) {
      diff.token = token;
      diff.prevToken = prevToken;
    }
    if (clientId !== prevClientId) {
      diff.clientId = clientId;
      diff.prevClientId = prevClientId;
    }
    if (assetId !== prevAssetId) {
      diff.assetId = assetId;
      diff.prevAssetId = prevAssetId;
    }
    if (messageId !== prevMessageId) {
      diff.messageId = messageId;
      diff.prevMessageId = prevMessageId;
    }
    if (canViewMedia !== prevCanViewMedia) {
      diff.canViewMedia = canViewMedia;
      diff.prevCanViewMedia = prevCanViewMedia;
    }
    if (
      isDrmLicenseAvailabilityFetched !== prevIsDrmLicenseAvailabilityFetched
    ) {
      diff.isDrmLicenseAvailabilityFetched = isDrmLicenseAvailabilityFetched;
      diff.prevIsDrmLicenseAvailabilityFetched =
        prevIsDrmLicenseAvailabilityFetched;
    }
    if (shouldAutoplayPost && shouldAutoplayPost !== prevShouldAutoplayPost) {
      diff.shouldAutoplayPost = shouldAutoplayPost;
      diff.prevShouldAutoplayPost = prevShouldAutoplayPost;
    }
    if (isActiveVideo && isActiveVideo !== prevIsActiveVideo) {
      diff.isActiveVideo = isActiveVideo;
      diff.prevIsActiveVideo = prevIsActiveVideo;
    }
    if (
      isModalOpener &&
      isFullscreenModalOpened !== prevIsFullscreenModalOpened
    ) {
      // `isFullscreenModalOpened` here should be false
      // since the truthy case was taken care earlier.
      diff.isFullscreenModalOpened = isFullscreenModalOpened;
      diff.prevIsFullscreenModalOpened = prevIsFullscreenModalOpened;
    }
    if (!isEqual(source, prevSource)) {
      diff.source = source;
      diff.prevSource = prevSource;
    }

    if (Object.keys(diff).length) {
      playerLog({
        messages: [
          `(debug ${debugNo}) componentDidUpdate`,
          'trigger playpack() since props updated',
        ],
        data: { messageId, ...diff },
      });

      if ((!isShort && !isPost) || isActiveVideo || shouldAutoplayPost) {
        // SHORT only plays when it's active (isActiveVideo);
        // POST only plays when it should be playing (shouldAutoplayPost);
        // Others play immediately when critical props updated.
        this.playback(debugNo);
      }
    }

    if (option !== prevOption) {
      playerLog({
        messages: [`(debug ${debugNo}) componentDidUpdate`, 'option updated'],
        data: { option, prevOption },
      });
      for (const [key, value] of Object.entries(option)) {
        const videoElement = this._player?.getMediaElement?.();
        if (videoElement) {
          videoElement[key] = value;
        }
      }
    }
    if (isMuted !== prevIsMuted) {
      playerLog({
        messages: [`(debug ${debugNo}) componentDidUpdate`, 'muted updated'],
        data: { isMuted, prevIsMuted },
      });
      this._player?.muted(isMuted);
      playerPool.updateVideoElementPool({ muted: isMuted });
    }
  }

  componentWillUnmount() {
    const player = this._player;
    if (player) {
      const debugNo = player?.debugNo || '';
      playerLog({
        messages: [`(debug ${debugNo}) componentWillUnmount`],
      });
      this._unmounting = true;
      playerLog({
        messages: [
          `(debug ${debugNo}) componentWillUnmount`,
          'trigger deactivatePlayer()',
        ],
      });
      this.deactivatePlayer({
        player,
        debugNo,
        reason: 'component unmounting',
      });
    }
  }

  render() {
    const { style, children } = this.props;

    return (
      <PlayerContext.Consumer>
        {({ setPlayer }) => {
          this.setPlayer = setPlayer;
          return (
            <StyledShakaPlayer ref={this.videoContainerRef} style={style}>
              {children}
            </StyledShakaPlayer>
          );
        }}
      </PlayerContext.Consumer>
    );
  }
}

ShakaPlayer.propTypes = {
  isDrmLicenseAvailabilityFetched: PropTypes.bool,
  drmFeatureName: PropTypes.string,
  assetId: PropTypes.string,
  clientId: PropTypes.string,
  token: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  option: PropTypes.object,
  source: PropTypes.object,
  mediaType: PropTypes.string,
  keySystem: PropTypes.string,
  robustness: PropTypes.string,
  canViewMedia: PropTypes.bool,
  isMuted: PropTypes.bool,
  isNeedReloadCycle: PropTypes.bool,
  isActiveVideo: PropTypes.bool, // Shorts
  shouldAutoplayPost: PropTypes.bool, // Feed/Post
  shouldCombineTrailer: PropTypes.bool,
  shouldUseTrailer: PropTypes.bool,
  shouldUseSplash: PropTypes.bool,
  isFullscreenModalOpened: PropTypes.bool, // Feed/Post
  enabledImageFullscreenModal: PropTypes.bool, // Feed/Post
  messageId: PropTypes.string,
  splashVideo: PropTypes.object,
  openPlaybackNotSupportAlert: PropTypes.func,
  onLoadedMetadata: PropTypes.func,
  onEnded: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onProgressUpdate: PropTypes.func,
  onSetup: PropTypes.func,
  setIsVideoReloaded: PropTypes.func,
  drmLicenseDuration: PropTypes.number,
  widevineServerCertificateBase64: PropTypes.string,
};

ShakaPlayer.defaultProps = {
  isDrmLicenseAvailabilityFetched: false,
  drmFeatureName: '',
  assetId: '',
  clientId: '',
  token: '',
  style: {},
  children: null,
  option: {},
  source: {},
  mediaType: 'image',
  keySystem: null,
  robustness: null,
  canViewMedia: false,
  isMuted: true,
  isNeedReloadCycle: false,
  isActiveVideo: false,
  shouldAutoplayPost: false,
  shouldCombineTrailer: false,
  shouldUseTrailer: false,
  shouldUseSplash: false,
  isFullscreenModalOpened: false,
  enabledImageFullscreenModal: false,
  messageId: null,
  splashVideo: {},
  openPlaybackNotSupportAlert: () => null,
  onLoadedMetadata: () => null,
  onEnded: () => null,
  onPause: () => null,
  onPlay: () => null,
  onProgressUpdate: null,
  onSetup: () => null,
  setIsVideoReloaded: () => null,
  drmLicenseDuration: 600,
  widevineServerCertificateBase64: '',
};

const StyledShakaPlayer = styled.div`
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }
`;

export default ShakaPlayer;
